import React from 'react';
import Img from "gatsby-image";

const Hero = ({ img, title, description}) => (
  <>
    {img && <Img fluid={img.childImageSharp.fluid} className="w-100 h4 mw9 center" alt={title} title={title}/>}
    {title && description && <div className="ph6-l ph5-m ph2">
      <p className="pa2 display dark-gray f3 tc mb3 h3">{title}</p>
      <p className="sans-serif">{description}</p>
    </div>}
  </>
)

export default Hero;
