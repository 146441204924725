import React from 'react';
import Helmet from 'react-helmet';
import {StaticQuery, graphql} from 'gatsby';

const Seo = ({title, description, children}) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            facebook
            instagram
            twitter
          }
        }
      }  
    `}
    render={data => (
      <Helmet>
        <title>{title + " - " + data.site.siteMetadata.title}</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">{`
          [
            {
              "@context":"https://schema.org",
              "@type":"WebSite",
              "url":"${data.site.siteMetadata.siteUrl}",
              "name":"${data.site.siteMetadata.title}"
            },
            {
              "@context" : "http://schema.org",
              "@type" : "Organization",
              "name" : "${data.site.siteMetadata.title}",
              "url" : "${data.site.siteMetadata.siteUrl}",
              "logo" : "${data.site.siteMetadata.siteUrl}/favicon.png"
            ,
            "sameAs" : [
              "${data.site.siteMetadata.facebook}",
              "${data.site.siteMetadata.instagram}",
              "${data.site.siteMetadata.twitter}"
            ]
            }
          ]
          `}
        </script>
        {children}
      </Helmet>
    )} />
)

export default Seo;
