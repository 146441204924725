import React from "react"
import Card from "../card/card";

const CategoryList = ({ category, items }) => {

  const { title, slug } = category;

  return (
    <div className="cf pa2">
      <div className="flex items-center">
        <h2 className="flex-auto pa2 display dark-gray f3 tl mb3 h3">{title}</h2>
      </div>
      {items.map((node, key) => {
        return (
          <Card
          key={key}
          to={node.brand.slug+slug.substring(1)+node.slug.substring(1)}
          img={node.localImage.childImageSharp.fixed}
          title={node.title}
          brand={node.brand.title}/>
          )
      })}
    </div>
  );
}

export default CategoryList;
