import React from "react"
import {Link} from "gatsby";
import Img from "gatsby-image";

const Card = ({to, img, title, brand}) => (
  <div className="fl w-25 w-20-m w-10-l pa2">
    <Link to={to} className="db link dim tc">
      <Img fixed={img} className="w-25 db black-10" alt={`${title} - ${brand}`} title={`${title} - ${brand}`}/>
      <dl className="mt2 f6 lh-copy">
        <dt className="ml0 black truncate w-100">{title}</dt>
      </dl>
    </Link>
  </div>
)

export default Card;
