import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout";
import Hero from "../components/hero/hero";
import CategoryList from "../components/category/categoryList";
import Seo from "../components/seo/seo";

export default ({ data, location }) => {
  const { localBrandHeroImg, title, description, extended, metaTitle, metaDescription, id } = data.brand;

  const categories = data.categories.nodes
    .map(category => ({...category, items: category.items.filter(item => item.brand.id === id)}))
    .filter(category => category.items.length > 0);

  return (
    <Layout title={metaTitle} location={location}>
        <Seo title={metaTitle} description={metaDescription}>
        </Seo>
      <Hero
        img={localBrandHeroImg}
        title={title}
        description={description}
      />
      <div className="ph6-l ph5-m ph2">
        {categories.map((category, key) => <CategoryList category={category} items={category.items} key={key}/> )}
        {extended !== '' && <>
        <div className="cf pa2 sans-serif">
          <h2 className="flex-auto pa2 display dark-gray f3 tl mb3 h3">{`¿Por qué comprar pintalabios ${title}?`}</h2>
          <div dangerouslySetInnerHTML={{__html: extended}} />
        </div>
        </>}
      </div>
    </Layout>
  )
}

export const query = graphql`
    query($slug: String!) {
        brand: brands(slug: {eq: $slug}) {
            id
            slug
            title
            heroImg
            description
            extended
            metaTitle
            metaDescription
            localBrandHeroImg {
                childImageSharp {
                    fluid(maxWidth: 1200, maxHeight: 300) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
        categories: allCategories {
            nodes {
                title
                slug
                items {
                    slug
                    title
                    brand {
                        id
                        title
                        slug
                    }
                    localImage {
                        childImageSharp {
                            fixed(width: 40, height: 120) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            }
        }
    }
`
